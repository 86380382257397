import './Modal.scss';

import alert_icon from '../assets/icons/timer.svg';

export default function ShiftTimeoutModal({ handleClose }) {
  
  return (
    <div className="modal">
      <div className="modal-content">

        <div className="modal-header">
            <img className="modal-header-icon" src={alert_icon}/>
            <p className="modal-title">Schicht abgelaufen</p>
        </div>

        <p className="modal-text">Bitte aktualisiere die Schichtinformation</p>

        <div className="save-delete-buttons">
            <button className="settings-modal-button light-button" onClick={handleClose}>Okay</button>
        </div>
      </div>
    </div>
  );
};