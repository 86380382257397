import React from "react";
import './Modal.scss';

import { AuthContext } from '../context/AuthContext';
import { firestore } from "../context/FirebaseConfig";

import { doc, setDoc } from "firebase/firestore";

import close_icon from '../assets/icons/close.svg';
import supplier_icon from '../assets/icons/supplier.png'

export default function AddSupplierModal({ handleClose, changeSupplier }) {
    const { currentUser } = React.useContext(AuthContext);
    const [ id, setId ] = React.useState("");
    const [ name, setName ] = React.useState("");

    function addNewSupplier() {
        /* Add a new user to the database to select in the dropdown */
        if (id === "" || name === "") {
            return;
        }

        const supplierKey = `${id}_${name}`.replace(/\s/g, "_").toLowerCase();
        const q = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        setDoc(q, {"suppliers": {[supplierKey]: {id: id, name: name}}}, {merge: true});

        // Change the supplier to the newly added supplier
        changeSupplier(supplierKey);
    }
  
    return (
        <div className="modal">
            <div className="modal-content">

                <div className="modal-header">
                    <p className="modal-title">Neuen Lieferant hinzufügen</p>
                    <button className="modal-close-button" onClick={handleClose}>
                        <img src={close_icon}/>
                    </button>
                </div>

                <div className="add-user-section">
                    <img className="shift-settings-icon" src={supplier_icon}/>
                    <p>ID</p>
                    <p>Name</p>
                    <input type="text" value={id} onChange={(e) => {setId(e.target.value)}}/>
                    <input type="text" value={name} onChange={(e) => {setName(e.target.value)}}/>
                </div>

                <div className="save-delete-buttons">
                    <button className="settings-modal-button light-button" onClick={() => {addNewSupplier(); handleClose();}}>Speichern</button>
                </div>
            </div>
        </div>
    );
};
