import './Modal.scss';

import alert_icon from '../assets/icons/alert.png';

export default function StampTimeoutModal({ handleYes, handleNo, settings, selection }) {
  
  return (
    <div className="modal">
      <div className="modal-content">

        <div className="modal-header">
            <img className="modal-header-icon" src={alert_icon}/>
            <p className="modal-title">Stempel noch Aktiv?</p>
        </div>

        <p className="modal-text">Ist der ausgewählte Lieferanten-Stempel noch aktiv?</p>

        {selection && selection?.supplier && 
        <div className="stamp-infos">
            <p>{settings?.suppliers?.[selection.supplier]?.id ?? selection.supplier}</p>
            <p>{settings?.suppliers?.[selection.supplier]?.name ?? selection.supplier}</p>
            <p>Feld {selection.field}</p>
        </div>}

        <div className="save-delete-buttons">
            <button className="settings-modal-button light-button" onClick={handleYes}>Ja</button>
            <button className="settings-modal-button light-button" onClick={handleNo}>Nein</button>
        </div>
      </div>
    </div>
  );
};