import React from "react";
import './Modal.scss';

import { AuthContext } from '../context/AuthContext';
import { firestore } from "../context/FirebaseConfig";

import { doc, setDoc } from "firebase/firestore";

import close_icon from '../assets/icons/close.svg';
import user_icon from '../assets/icons/user.png'

export default function AddUserModal({ handleClose, changeUser }) {
    const { currentUser } = React.useContext(AuthContext);
    const [ firstname, setFirstname ] = React.useState("");
    const [ lastname, setLastname ] = React.useState("");

    function addNewUser() {
        /* Add a new user to the database to select in the dropdown */
        const userKey = `${firstname}_${lastname}`.replace(/\s/g, "_").toLowerCase();
        
        const q = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        setDoc(q, {"users": {[userKey]: {firstname: firstname, lastname: lastname}}}, {merge: true});

        // Change the user to the newly added user
        changeUser(userKey);
    }
  
    return (
        <div className="modal">
            <div className="modal-content">

                <div className="modal-header">
                    <p className="modal-title">Neuen User hinzufügen</p>
                    <button className="modal-close-button" onClick={handleClose}>
                        <img src={close_icon}/>
                    </button>
                </div>

                <div className="add-user-section">
                    <img className="shift-settings-icon" src={user_icon}/>
                    <p>Vorname</p>
                    <p>Nachname</p>
                    <input type="text" value={firstname} onChange={(e) => {setFirstname(e.target.value)}}/>
                    <input type="text" value={lastname} onChange={(e) => {setLastname(e.target.value)}}/>
                </div>

                <div className="save-delete-buttons">
                    <button className="settings-modal-button light-button" onClick={() => {addNewUser(); handleClose();}}>Speichern</button>
                </div>
            </div>
        </div>
    );
};
