import './Modal.scss';

export default function ConfirmationModal({ message, handleSubmit, handleClose }) {
  
  return (
    <div className="modal">
      <div className="modal-content">

        <p className="modal-title">{message}</p>

        <div className="save-delete-buttons">
            <button className="settings-modal-button light-button" onClick={() => {handleSubmit(); handleClose();}}>Ja</button>
            <button className="settings-modal-button light-button" onClick={handleClose}>Abbrechen</button>
        </div>
      </div>
    </div>
  );
}