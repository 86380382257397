import { firestore } from "../context/FirebaseConfig";
import { collection, query, where, doc, getDoc, getDocs } from "firebase/firestore";

export async function fetchUserInfo(uid)  {
    const userInfo = {};

    // Fetch user company & language
    const userDocSnap = await getDoc(doc(firestore, "users", uid));
    if (!userDocSnap.exists()) {
        return;
    }
    userInfo["info"] = userDocSnap.data();
    const company = userDocSnap.data().company;

    // Fetch company display name
    const clientDocSnap = await getDoc(doc(firestore, "clients", company));
    if (clientDocSnap.exists()) {
        userInfo["company"] = clientDocSnap.data();
    }

    // Fetch device
    const deviceQuery = query(collection(firestore, "clients", company, "devices"), where("client", "==", company));
    const querySnapshot = await getDocs(deviceQuery);
    const deviceData = querySnapshot.docs[0].data()
    userInfo["device_id"] = deviceData.device_id;

    userInfo["local_ip"] = deviceData.local_ip;
    userInfo["visualization_topics"] = deviceData.visualization_topics;

    if (deviceData.filter_observation_classes) {
        userInfo["filter_observation_classes"] = deviceData.filter_observation_classes
    }

    return userInfo;
}

export async function fetchLabelsets(company) {
    const labelsets = {};
    const labelsetsSnapshot = await getDocs(collection(firestore, "clients", company, "labelsets"));
    labelsetsSnapshot.forEach((doc) => {
        labelsets[doc.id] = {...doc.data()};
    });
    
    return labelsets;
}