import React from "react";
import "./ShiftSettingsModal.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";

import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { addDays, setHours, setMinutes } from "date-fns";

import AddUserModal from "../AddUserModal";

// Importing icons
import user_icon from '../../assets/icons/user.png';
import timer_icon from '../../assets/icons/timer.svg';
import close_icon from '../../assets/icons/close.svg';

export default function ShiftSettingsModal({ handleClose, settings }) {
    const { currentUser } = React.useContext(AuthContext);
    const [ shiftInfo, setShiftInfo ] = React.useState({
        "user": "",
        "feed_shift": "A",
        "work_shift": 0,
    });
    const [ showNewUserModal, setShowNewUserModal ] = React.useState(false);

    React.useEffect(() => {
        const q = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        onSnapshot(q, (snapshot) => {
            if (!snapshot.exists || !snapshot.data() || !("lastPlantFeedShiftInfo" in snapshot.data())) {
                return;
            }
            const snapShiftInfo = snapshot.data().lastPlantFeedShiftInfo;
            const shiftIndex = currentUser.company.shifts.findIndex(shift => shift.name === snapShiftInfo.work_shift.name);
            setShiftInfo({...snapShiftInfo, "work_shift": shiftIndex===-1 ? 0 : shiftIndex});
        });
    }, []);

    function handleChange(key, value) {
        setShiftInfo((prev) => {
            return {
                ...prev,
                [key]: value,
            }
        })
    }
    
    function saveAndClose() {
        /* Save the shift info to the database and close modal */
        // Calculate the expiration time of the shift
        const [hours, minutes] = currentUser.company.shifts[shiftInfo.work_shift].end_time.split(':').map(Number);
        let shiftExpiration = new Date();
        shiftExpiration = setHours(shiftExpiration, hours);
        shiftExpiration = setMinutes(shiftExpiration, minutes);
        if (shiftExpiration < new Date()) {
            shiftExpiration = addDays(shiftExpiration, 1);
        }

        // Save the shift info to the database
        const q = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        updateDoc(q, {"lastPlantFeedShiftInfo": {
            ...shiftInfo,
            "work_shift": currentUser.company.shifts[shiftInfo.work_shift],
            "shift_expiration_timestamp": shiftExpiration,
        }});
        handleClose();
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <p className="modal-title">Einstellungen</p>
                </div>
                <div className="shift-settings-center">
                    <div className="shift-settings-user">
                        <img className="shift-settings-user-icon" src={user_icon}/>
                        <p>User</p>
                        <select className="shift-settings-user-select"
                            value={shiftInfo.user}
                            onChange={(e) => {handleChange("user", e.target.value)}}
                        >
                            <option key={-1} value={""}></option>
                            { settings?.users && Object.keys(settings.users).length > 0 && 
                            Object.entries(settings?.users)
                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                            .map(([key, value]) => {
                                return <option key={key} value={key}>{value?.firstname} {value?.lastname}</option>
                            })}
                        </select>
                        <button className="shift-settings-add-user" onClick={() => {setShowNewUserModal(true)}}>+</button>
                    </div>
                    <div className="shift-settings-shift">
                        <img className="shift-settings-shift-icon" src={timer_icon}/>
                        <p>Arbeitsschicht</p>
                        <p>Produktionsschicht</p>
                        <select className="shift-settings-dropdown" 
                            onChange={(e) => {handleChange("work_shift", e.target.value)}}
                            value={shiftInfo.work_shift}
                        >
                            { currentUser.company.shifts.map((shift, index) => {
                                return <option key={index} value={index}>{shift.name}</option>
                            })}
                        </select>
                        <select className="shift-settings-dropdown" 
                            onChange={(e) => {handleChange("feed_shift", e.target.value)}}
                            value={shiftInfo.feed_shift}
                        >
                            <option key={'A'} value={'A'}>A</option>
                            <option key={'B'} value={'B'}>B</option>
                            <option key={'C'} value={'C'}>C</option>
                            <option key={'D'} value={'D'}>D</option>
                            <option key={'E'} value={'E'}>E</option>
                        </select>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="settings-modal-button" onClick={saveAndClose}>Speichern</button>
                </div>
            </div>
            {showNewUserModal && <AddUserModal 
                handleClose={() => setShowNewUserModal(false)}
                changeUser={(user) => {handleChange("user", user)}}
            />}
        </div>
    )
}