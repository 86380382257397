import React from "react";
import './Modal.scss';

import { AuthContext } from '../context/AuthContext';
import { firestore } from "../context/FirebaseConfig";

import { doc, setDoc } from "firebase/firestore";

import close_icon from '../assets/icons/close.svg';
import field_icon from '../assets/icons/label.png'

export default function AddFieldModal({ handleClose, changeField }) {
    const { currentUser } = React.useContext(AuthContext);
    const [ field, setField ] = React.useState("");

    function addNewField() {
        /* Add a new user to the database to select in the dropdown */
        if (field === "") {
            return;
        }

        const fieldKey = field.replace(/\s/g, "_").toLowerCase();
        const q = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        setDoc(q, {"fields": {[fieldKey]: {field: field}}}, {merge: true});

        // Change the field to the newly added field
        changeField(fieldKey);
    }
  
    return (
        <div className="modal">
            <div className="modal-content">

                <div className="modal-header">
                    <p className="modal-title">Neues Feld hinzufügen</p>
                    <button className="modal-close-button" onClick={handleClose}>
                        <img src={close_icon}/>
                    </button>
                </div>

                <div className="add-user-section">
                    <img className="shift-settings-icon" src={field_icon}/>
                    <p style={{gridColumn: "span 2"}}>Feld</p>
                    <input style={{gridColumn: "span 2"}} type="text" value={field} onChange={(e) => {setField(e.target.value)}}/>
                </div>

                <div className="save-delete-buttons">
                    <button className="settings-modal-button light-button" onClick={() => {addNewField(); handleClose();}}>Speichern</button>
                </div>
            </div>
        </div>
    );
};
