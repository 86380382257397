import { createContext, useEffect, useState } from "react";
import { auth } from "./FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { firestore } from "./FirebaseConfig";
import { arrayUnion, doc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { fetchUserInfo, fetchLabelsets } from "../utils/FirebaseUtils";

export const AuthContext = createContext();

//----------------------------------------------
//TODO: Remove materials and materials_predicted once labelsets is properly implemented
//----------------------------------------------

// Setting up an app-wide user authentication provider. 
export const AuthContextProvider = ({children}) => {
    const [authUser, setAuthUser] = useState({});
    const [ userLoaded, setUserLoaded ] = useState(false);
    const [currentUser, setCurrentUser] = useState({
        uid: null,
        info:{languageCode:"de"}, 
        company:{company_displayname:"", company:"", shift_time:0},
        device_id: null,
        filter_observation_classes: [],
    });
    const [labelsets, setLabelsets] = useState({});

    async function addTimestampToUser(user) {
        updateDoc(doc(firestore, 'users', user), {last_login: serverTimestamp()});
        setDoc(doc(firestore, 'tests', 'user_login_timestamps'), {
        [user]: arrayUnion(new Date().toISOString()) // Using Firestore's server timestamp and arrayUnion
        }, { merge: true });
    }

    async function getUserData(user) {
        const currentUserNew = await fetchUserInfo(user.uid);
        currentUserNew['uid'] = user.uid;

        if (currentUserNew.company) {
            await getLabelsets(currentUserNew.company.company);
        }
        setCurrentUser(currentUserNew);
        setUserLoaded(true);
    }

    async function getLabelsets(company) {
        if (!company) return;

        const labelsetsNew = await fetchLabelsets(company);        
        setLabelsets(labelsetsNew);
    }

    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            setAuthUser(user);
            setUserLoaded(false);
            if (user) {
                getUserData(user);
                addTimestampToUser(user.uid)
            } else {
                setCurrentUser({});
            }
        });

    }, []);

    return(
        // Any component wrapped into "authcontext provider" will be able to reach the current user. 
        // Import into index file to cover the whole application. 
        <AuthContext.Provider value={{authUser, currentUser, userLoaded, labelsets}}>
            {children}
        </AuthContext.Provider>
    );
};