import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Importing custom pages
import Login from './pages/Login/Login';
import LoadingScreen from './components/LoadingScreen';
import FeedTimestamping from './pages/FeedTimestamping/FeedTimestamping';
import Corrections from './pages/Corrections/Corrections';

// Importing context provider. 
import { AuthContext } from './context/AuthContext';

function App() {

  // finding the current user
  const { authUser, currentUser, userLoaded } = useContext(AuthContext);

  // Creating a protected route to only allow logged-in users to access the dashboard. 
  const ProtectedRoute = ({children}) => {
    if(!authUser){
      return <Navigate to="/login"/>
    }
    // else
    return children
  }

  const LimitedAccessRoute = ({acceptedUserFlags, children}) => {
    if (currentUser && currentUser.info && currentUser.info.user_flags && 
        acceptedUserFlags.some(flag => currentUser.info.user_flags.includes(flag))) {
      return children;
    }
    return <Navigate to="/"/>
  }

  if (authUser && !userLoaded) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Navigate to={"feed-timestamping"}/>} />
          <Route path="login" element={<Login/>} />
          <Route path="feed-timestamping" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','shift-reporter','labeler']}><FeedTimestamping/></LimitedAccessRoute></ProtectedRoute>} />
          <Route path="corrections" element={<ProtectedRoute><LimitedAccessRoute acceptedUserFlags={['admin','shift-reporter','labeler']}><Corrections/></LimitedAccessRoute></ProtectedRoute>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
