// This file includes firebase resources and API, 
// exported to enable authentication and use across all other scripts. 

// firebase resources
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Firebase webapp configuration (app nickname: Analytics Dashboard)
const firebaseConfig = {
    apiKey: "AIzaSyC9K39Yu1OqDsaDdC2q8tPqsTTT9-ksa9M",
    authDomain: "upcircleai-dashboard.firebaseapp.com",
    projectId: "upcircleai-dashboard",
    storageBucket: "upcircleai-dashboard.appspot.com",
    messagingSenderId: "951071303444",
    appId: "1:951071303444:web:0f9f5863f17b2797484401",
    measurementId: "G-QZQ868R21T"
};  

// initialize firebase and resources
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

// connect to firebase emulator if in development mode
const USE_EMULATOR = false;
const EMULATOR_IP = '192.168.178.24'
if (USE_EMULATOR) {
    connectFirestoreEmulator(firestore, EMULATOR_IP, 8080);
    connectFunctionsEmulator(functions, EMULATOR_IP, 5001);
}

export { firebaseApp, auth, storage, firestore, functions }